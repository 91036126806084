var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.deliveryNotification.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"my-0 pb-0",attrs:{"cols":"12"}},[_c('h3',{staticClass:"headline pb-2",attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$t('delivery_notifications')))])])],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 pb-5",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('delivery_notification_text', {'_sum_': _vm.sum}))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"name",attrs:{"rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-courier","label":_vm.$t('name_for'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"phone",attrs:{"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"type":"tel","error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-phone","label":_vm.$t('phone'),"color":"primary","outlined":"","rounded":"","dense":""},model:{value:(_vm.phoneRaw),callback:function ($$v) {_vm.phoneRaw=$$v},expression:"phoneRaw"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"email",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors,"disabled":_vm.loading,"error":!valid,"prepend-icon":"mdi-email","label":_vm.$t('email'),"outlined":"","rounded":"","dense":"","color":"primary","clearable":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"notifications",attrs:{"rules":"required|min:6|max:1255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"label":_vm.$t('notifications_list'),"rows":"5","color":"primary","auto-grow":"","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.notifications),callback:function ($$v) {_vm.notifications=$$v},expression:"notifications"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-8"},[_c('v-progress-linear',{staticClass:"mx-2",attrs:{"active":_vm.loading},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading,"loading":_vm.loading,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('send_request'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }