<template>
    <v-container fluid  >
        <v-form @submit.prevent="deliveryNotification">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col class="my-0 pb-0" cols="12">
                                <h3 class="headline pb-2" color="black">{{ $t('delivery_notifications') }}</h3>
                            </v-col>
                        </v-row>
                        <v-row class="my-0 py-0">
                            <v-col class="my-0 pb-5" cols="12" >
                                {{ $t('delivery_notification_text', {'_sum_': sum})}}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" >
                                <ValidationProvider ref="name" rules="required|min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-courier"
                                                  :label="$t('name_for')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="phone" rules="required|phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="phoneMask"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone"
                                                  :label="$t('phone')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense

                                    >
                                    </v-text-field>
                                </ValidationProvider>

                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="email" rules="required|email"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="email" type="email" :error-messages="errors"
                                                  :disabled="loading" :error="!valid"
                                                  prepend-icon="mdi-email" :label="$t('email')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider ref="notifications" rules="required|min:6|max:1255"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="notifications" type="text"
                                                :error="!valid" :error-messages="errors"
                                                :disabled="loading" :label="$t('notifications_list')"
                                                rows="5" color="primary" auto-grow
                                                outlined
                                                rounded
                                                dense
                                                clearable>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>




                    </v-card-text>

                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('send_request') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";

export default {
    name: 'ProfileForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            phoneRaw: null,
            name: null,
            email: null,
            notifications: null,
            sum: 0,

        }
    },
    computed: {
        ...mapGetters(['lang', 'phoneMask', 'phoneIntMask', 'lang']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
    },
    mounted() {
        this.getDeliveryNotification()
    },
    methods: {
        ...mapActions(['setUserName', 'setUserPosition', 'setUserAvatar']),

        async getDeliveryNotification() {
            this.loading = true
            let params = {};
            if (this.lang) {
                params.language = this.lang
            }
            await this.$http
                .get("account/delivery/notification", {
                    params: params,
                })
                .then(res => {
                    this.sum = res.body.data.sum
                    this.name = res.body.data.name
                    this.phoneRaw = res.body.data.phone
                    this.email = res.body.data.email
                })
                .catch(err => {
                    this.sum = 300
                    this.$toastr.error(this.$t('failed_to_get_delivery_notification'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deliveryNotification() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.name) {
                formData.append('name', this.name)
            }
            if (this.notifications) {
                formData.append('notifications', this.notifications)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }
            if (this.email) {
                formData.append('email', this.email)
            }
            if (this.lang) {
                formData.append('language', this.lang)
            }

            // Save
            await this.$http
                .post(`account/delivery/notification`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('request_delivery_data_has_been_sent'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_request_provide_delivery_data'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },

    }
}
</script>
